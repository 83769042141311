import React from 'react';
import styled from 'styled-components';
import { lighten, darken } from 'polished';
import * as t from '../Typography';
import Colors from '../Colors';
import LN from '../images/ln.png';
import Close from '../images/close-popup-grey.png';
import { media } from '../MediaQueries';
import Helmet from 'react-helmet';

const HireMePopupWrapper = styled.div`
  ${t.H3} {
    color: ${Colors.darkest};
    margin-block-start: 0em;
    margin-block-end: 0em;
    display: flex;
    flex: 1;
    align-items: center;
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${Colors.white};
  z-index: 1000;
  display: flex;
  flex-direction: row;
  ${props =>
    props.open === false &&
    `
    display: none;
  `}
  ${media.tablet`
    flex-direction: column;
    overflow-y: auto;
  `};
`;

const Link = styled.a`
  // font-size: 18px;
  // padding: 16px 50px;
  color: ${Colors.darkest};
`;

const ContentWrapper = styled.div`
  display: flex;
  max-width: 500px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(8, 47, 78);
  padding: 4em;
  height: 60%;
  align-self: center;
  ${media.tablet`
    text-align: center;
    height: 100%;
  `};
`;

const Burger = styled.div`
  width: 22px;
  height: 22px;
  align-self: center;
  position: absolute;
  right: 25px;
  top: 25px;
  color: ${Colors.closeIcon};
  z-index: 2;
  color: ${props => props.scrolled && Colors.darkest};
  &:hover {
    ${props => (props.scrolled || props.theme === 'white' ? lighten(0.3, Colors.darkest) : darken(0.1, Colors.white))};
    cursor: pointer;
    opacity: 0.9;
  }
  img {
    width: 20px;
    height: 20px;
  }
`;

class HireMePopup extends React.Component {
  closePopup = e => {
    this.props.handleClose(false);
  };

  render() {
    const { open } = this.props;
    const overflow = open ? 'hidden' : 'auto';
    return (
      <HireMePopupWrapper open={open}>
        <Helmet>
          <body style={{ overflow: overflow }} />
        </Helmet>
        <ContentWrapper>
          <Burger onClick={this.closePopup}>
            <img alt="Close popup" src={Close} />
          </Burger>
          <t.H3 style={{ flex: '2', alignItems: 'flex-start' }}>Ways to contact me</t.H3>
          <Link
            primary
            bold
            target="_blank"
            rel="noopener noreferrer"
            style={{ flex: '1' }}
            href="https://www.linkedin.com/in/christopher-pietka/"
          >
            <img
              src={LN}
              style={{ backgroundColor: '#fafafa', padding: '.5em' }}
              alt="Christopher Pietka Linkedin Profile"
            />
          </Link>
          <t.H3>
            <Link target="_blank" rel="noopener noreferrer" href="mailto:chrispietka@yahoo.com">
              Email: chrispietka@yahoo.com
            </Link>
          </t.H3>
          <t.H3>
            <Link target="_blank" rel="noopener noreferrer" href="tel:+1(630)5233117">
              Phone: (630) 523 - 3117
            </Link>
          </t.H3>
        </ContentWrapper>
      </HireMePopupWrapper>
    );
  }
}

export default HireMePopup;
