const Colors = {
  white: '#2a2a2a', //used for header, darkgrey
  light: '#082f4e', //used for accents and buttons, darkblue
  lightGrey: '#e5dada',
  dark: '#b5a397',
  darker: '#6a5750',
  darkest: '#fafafa', //links, buttons, and footer, moonwhite
  closeIcon: '#fafafa'
};

export default Colors;
